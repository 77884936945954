import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MessageService, ConfirmationService } from 'primeng/api';
import { NgxPermissionsService, NgxPermissionsModule } from 'ngx-permissions';
import { BASE_PATH as BASE_PATH_auth } from '@src/api/auth';
import { BASE_PATH as BASE_PATH_v1 } from '@src/api/v1';
import { BASE_PATH as BASE_PATH_v2 } from '@src/api/v2';
import { environment } from '@src/environments/environment';
import { TokenInterceptor, ErrorHandlerInterceptor, RetryInterceptor, SessionService } from '@src/app/modules/auth';
import { CaptionPipe } from '@src/app/pipes';

import { routes } from './app.routes';

registerLocaleData(localeRu, 'ru');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    MessageService,
    ConfirmationService,
    NgxPermissionsService,
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    SessionService,
    CaptionPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (session: SessionService) => async () => {
        return session.init();
      },
      deps: [SessionService],
      multi: true,
    },
    {
      provide: BASE_PATH_auth,
      useValue: environment.basePaths.auth,
    },
    {
      provide: BASE_PATH_v1,
      useValue: environment.basePaths.v1,
    },
    {
      provide: BASE_PATH_v2,
      useValue: environment.basePaths.v2,
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  ],
};
