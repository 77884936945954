import { Routes } from '@angular/router';
import { AuthGuard, UnAuthGuard, RedirectGuard } from '@src/app/modules/auth';

import { getPermissionGuardProps } from './utils';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login-page').then(module => module.LoginPageComponent),
    canActivate: [UnAuthGuard],
  },
  {
    path: '',
    loadComponent: () => import('./components/app-layout').then(module => module.AppLayoutComponent),
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: '',
      //   loadComponent: () => import('./pages/projects-page').then(module => module.ProjectsPageComponent),
      //   canActivate: [RedirectGuard],
      // },
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full',
      },
      {
        path: 'projects',
        loadComponent: () => import('./pages/projects-page').then(module => module.ProjectsPageComponent),
        ...getPermissionGuardProps('projectsPageViewing'),
        children: [
          {
            path: 'new',
            loadComponent: () => import('./pages/project-form-page').then(module => module.ProjectFormPageComponent),
          },
          {
            path: ':projectId',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./pages/project-form-page').then(module => module.ProjectFormPageComponent),
              },
              {
                path: 'channels',
                children: [
                  {
                    path: 'new',
                    loadComponent: () =>
                      import('./pages/channel-form-page').then(module => module.ChannelFormPageComponent),
                  },
                  {
                    path: ':channelId',
                    loadComponent: () =>
                      import('./pages/channel-form-page').then(module => module.ChannelFormPageComponent),
                  },
                ],
              },
              {
                path: 'services',
                children: [
                  {
                    path: 'new',
                    loadComponent: () =>
                      import('./pages/service-form-page').then(module => module.ServiceFormPageComponent),
                  },
                  {
                    path: ':serviceId',
                    children: [
                      {
                        path: '',
                        loadComponent: () =>
                          import('./pages/service-form-page').then(module => module.ServiceFormPageComponent),
                      },
                      {
                        path: 'adapters',
                        children: [
                          {
                            path: 'new',
                            loadComponent: () =>
                              import('./pages/adapter-form-page').then(module => module.AdapterFormPageComponent),
                          },
                          {
                            path: ':adapterId',
                            children: [
                              {
                                path: '',
                                loadComponent: () =>
                                  import('./pages/adapter-form-page').then(module => module.AdapterFormPageComponent),
                              },
                              {
                                path: 'conditions',
                                children: [
                                  {
                                    path: 'new',
                                    loadComponent: () =>
                                      import('./pages/condition-form-page').then(
                                        module => module.ConditionFormPageComponent,
                                      ),
                                  },
                                  {
                                    path: ':conditionId',
                                    loadComponent: () =>
                                      import('./pages/condition-form-page').then(
                                        module => module.ConditionFormPageComponent,
                                      ),
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'incoming-adapters-group',
                        children: [
                          {
                            path: ':adaptersGroupId',
                            children: [
                              {
                                path: '',
                                loadComponent: () =>
                                  import('./pages/incoming-adapters-group-form-page').then(
                                    module => module.IncomingAdaptersGroupFormPageComponent,
                                  ),
                              },
                              {
                                path: 'incoming-adapters',
                                children: [
                                  {
                                    path: 'new',
                                    loadComponent: () =>
                                      import('./pages/incoming-adapters-form-page').then(
                                        module => module.IncomingAdaptersFormPageComponent,
                                      ),
                                  },
                                  {
                                    path: ':adapterId',
                                    children: [
                                      {
                                        path: '',
                                        loadComponent: () =>
                                          import('./pages/incoming-adapters-form-page').then(
                                            module => module.IncomingAdaptersFormPageComponent,
                                          ),
                                      },
                                      {
                                        path: 'conditions',
                                        children: [
                                          {
                                            path: 'new',
                                            loadComponent: () =>
                                              import('./pages/condition-form-page').then(
                                                module => module.ConditionFormPageComponent,
                                              ),
                                          },
                                          {
                                            path: ':conditionId',
                                            loadComponent: () =>
                                              import('./pages/condition-form-page').then(
                                                module => module.ConditionFormPageComponent,
                                              ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'routers-group',
                        children: [
                          {
                            path: ':routersGroupId',
                            children: [
                              {
                                path: '',
                                loadComponent: () =>
                                  import('./pages/routers-group-form-page').then(
                                    module => module.RoutersGroupFormPageComponent,
                                  ),
                              },
                              {
                                path: 'outgoing-adapters',
                                children: [
                                  {
                                    path: 'new',
                                    loadComponent: () =>
                                      import('./pages/outgoing-adapters-form-page').then(
                                        module => module.OutgoingAdaptersFormPageComponent,
                                      ),
                                  },
                                  {
                                    path: ':adapterId',
                                    children: [
                                      {
                                        path: '',
                                        loadComponent: () =>
                                          import('./pages/outgoing-adapters-form-page').then(
                                            module => module.OutgoingAdaptersFormPageComponent,
                                          ),
                                      },
                                      {
                                        path: 'conditions',
                                        children: [
                                          {
                                            path: 'new',
                                            loadComponent: () =>
                                              import('./pages/condition-form-page').then(
                                                module => module.ConditionFormPageComponent,
                                              ),
                                          },
                                          {
                                            path: ':conditionId',
                                            loadComponent: () =>
                                              import('./pages/condition-form-page').then(
                                                module => module.ConditionFormPageComponent,
                                              ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'loggers',
                        children: [
                          {
                            path: 'new',
                            loadComponent: () =>
                              import('./pages/logger-form-page').then(module => module.LoggerFormPageComponent),
                          },
                          {
                            path: ':loggerId',
                            loadComponent: () =>
                              import('./pages/logger-form-page').then(module => module.LoggerFormPageComponent),
                          },
                        ],
                      },
                      {
                        path: 'routers',
                        children: [
                          {
                            path: 'new',
                            loadComponent: () =>
                              import('./pages/router-form-page').then(module => module.RouterFormPageComponent),
                          },
                          {
                            path: ':routerId',
                            loadComponent: () =>
                              import('./pages/router-form-page').then(module => module.RouterFormPageComponent),
                          },
                        ],
                      },
                      {
                        path: 'translators-group',
                        children: [
                          {
                            path: ':translatorsGroupId',
                            children: [
                              {
                                path: '',
                                loadComponent: () =>
                                  import('./pages/translators-group-form-page').then(
                                    module => module.TranslatorsGroupFormPageComponent,
                                  ),
                              },
                              {
                                path: 'translators',
                                children: [
                                  {
                                    path: 'new',
                                    loadComponent: () =>
                                      import('./pages/translator-form-page').then(
                                        module => module.TranslatorFormPageComponent,
                                      ),
                                  },
                                  {
                                    path: ':translatorId',
                                    loadComponent: () =>
                                      import('./pages/translator-form-page').then(
                                        module => module.TranslatorFormPageComponent,
                                      ),
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'commands',
                        children: [
                          {
                            path: 'new',
                            loadComponent: () =>
                              import('./pages/command-form-page').then(module => module.CommandFormPageComponent),
                          },
                          {
                            path: ':commandId',
                            loadComponent: () =>
                              import('./pages/command-form-page').then(module => module.CommandFormPageComponent),
                          },
                        ],
                      },
                      {
                        path: 'conditions',
                        children: [
                          {
                            path: 'new',
                            loadComponent: () =>
                              import('./pages/condition-form-page').then(module => module.ConditionFormPageComponent),
                          },
                          {
                            path: ':conditionId',
                            loadComponent: () =>
                              import('./pages/condition-form-page').then(module => module.ConditionFormPageComponent),
                          },
                        ],
                      },
                      {
                        path: 'credentials',
                        children: [
                          {
                            path: 'new',
                            loadComponent: () =>
                              import('./pages/credential-form-page').then(module => module.CredentialFormPageComponent),
                          },
                          {
                            path: ':credentialId',
                            loadComponent: () =>
                              import('./pages/credential-form-page').then(module => module.CredentialFormPageComponent),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'user-log',
        loadChildren: () => import('./pages/user-log-page').then(module => module.UserLogPageModule),
        ...getPermissionGuardProps('userLogPageViewing'),
      },
      {
        path: 'administration',
        ...getPermissionGuardProps('administrationPageViewing'),
        children: [
          {
            path: '',
            redirectTo: '/administration/users',
            pathMatch: 'full',
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/users-page').then(module => module.UsersPageModule),
            ...getPermissionGuardProps('usersPageViewing'),
          },
          {
            path: 'modules-settings',
            loadComponent: () =>
              import('./pages/modules-settings-page').then(module => module.ModulesSettingsPageComponent),
            ...getPermissionGuardProps('modulesSettingsPageViewing'),
          },
          {
            path: 'notification-rules',
            loadChildren: () =>
              import('./pages/setting-notification-rules-page').then(
                module => module.SettingNotificationRulesPageModule,
              ),
            ...getPermissionGuardProps('notificationRulesPageViewing'),
          },
          {
            path: 'user-log-old',
            loadChildren: () => import('./pages/user-log-page-old').then(module => module.OldUserLogPageModule),
            ...getPermissionGuardProps('userLogPageOldViewing'),
          },
        ],
      },
      {
        path: 'services',
        loadComponent: () => import('./pages/services-page').then(module => module.ServicesPageComponent),
        ...getPermissionGuardProps('servicesPageViewing'),
      },
      {
        path: 'connections',
        ...getPermissionGuardProps('connectionsPageViewing'),
        children: [
          {
            path: '',
            redirectTo: '/connections/adapters',
            pathMatch: 'full',
          },
          {
            path: 'adapters',
            loadComponent: () => import('./pages/adapters-page').then(module => module.AdaptersPageComponent),
            ...getPermissionGuardProps('adaptersPageViewing'),
          },
          {
            path: 'credentials',
            loadComponent: () => import('./pages/credentials-page').then(module => module.CredentialsPageComponent),
            ...getPermissionGuardProps('credentialsPageViewing'),
          },
        ],
      },
      {
        path: 'translators',
        loadComponent: () => import('./pages/translators-page').then(module => module.TranslatorsPageComponent),
        ...getPermissionGuardProps('translatorsPageViewing'),
      },
      {
        path: 'processing-conditions',
        ...getPermissionGuardProps('processingConditionsPageViewing'),
        children: [
          {
            path: '',
            redirectTo: '/processing-conditions/conditions',
            pathMatch: 'full',
          },
          {
            path: 'conditions',
            loadComponent: () => import('./pages/conditions-page').then(module => module.ConditionsPageComponent),
            ...getPermissionGuardProps('conditionsPageViewing'),
          },
          {
            path: 'commands',
            loadComponent: () => import('./pages/commands-page').then(module => module.CommandsPageComponent),
            ...getPermissionGuardProps('commandsPageViewing'),
          },
        ],
      },
      {
        path: '404',
        loadComponent: () => import('./pages/page-not-found').then(module => module.PageNotFoundComponent),
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
];
